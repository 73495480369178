import { DeviceUtil } from "../../umadash.js/util/DeviceUtil";

export class HSlider {

    private $elm: JQuery;
    private $items: JQuery;
    private currentIndex: number;
    private length: number;
    private parentWidth: number;



    constructor($elm: JQuery) {

        this.$elm = $elm;

        this.currentIndex = 0;
        this.length = 0;
    }

    public getCurrentIndex(): number {
        return this.currentIndex;
    }

    public getLength(): number {
        return this.length;
    }

    private layout(): void {
        const $items: JQuery = this.$elm.children();
        // let left: number = this.padding;
        // let maxHeight: number = -9999;
        // console.log('---------------');
        // $items.each((index: number, element: HTMLElement) => {
        //     const $element: JQuery = $(element);
        //     $element.css({
        //         left
        //     });

        //     const width: number = $element.outerWidth();
        //     const height: number = $element.outerHeight();
        //     console.log('height', height);
        //     left += width + this.margin;

        //     if (height > maxHeight) {
        //         maxHeight = height;
        //     }
        // });

        // this.$elm.css({
        //     width: left - this.margin,
        //     height: maxHeight
        // });
        // $items.css({
        //     height: maxHeight
        // });
        const $lastItem: JQuery = $items.last();
        const width = $lastItem.position().left + $lastItem.outerWidth();
        this.$elm.css({
            width
        });

        this.$items = $items;
        this.length = $items.length;
    }

    private moveAt(index: number): void {

        // 一番左にくるアイテム
        const $target: JQuery = this.$items.eq(index);

        // 一番左に来るアイテムの相対X座標
        const left: number = $target.position().left;

        const contentWidth: number = this.getContentWidth();

        // 右端の座標
        const right: number = -left + contentWidth;
        const base: number = this.parentWidth;

        if (right < base) {
            const x: number = -(this.getContentWidth() - this.parentWidth);
            this.$elm.css({
                transform: `translate(${x}px, 0)`
            });
        }
        else {
            const x: number = -(left);
            this.$elm.css({
                transform: `translate(${x}px, 0)`
            });
        }

        this.currentIndex = index;
    }

    public getWidth(): number {
        return this.$elm.outerWidth();
    }

    public moveLeft(): void {
        let nextIndex: number = this.currentIndex + 1;
        if (nextIndex >= this.length) {
            nextIndex = 0;
        }

        this.moveAt(this.checkAt(nextIndex, true));
    }

    public moveRight(): void {
        let jump: boolean = false;
        let nextIndex: number = this.currentIndex - 1;
        if (nextIndex < 0) {
            nextIndex = this.length - 1;
            jump = true
        }

        this.moveAt(this.checkAt(nextIndex, false, jump));
    }

    public enable(): void {
        this.$elm.removeClass('disable');
    }

    public disable(): void {
        this.$elm.addClass('disable');
    }

    public resize(parentWidth: number): void {
        this.parentWidth = parentWidth;
        this.layout();
    }

    public getContentWidth(): number {
        return this.$elm.outerWidth();
    }

    private checkAt(index: number, directionRight: boolean, jump: boolean = false): number {
        const $target: JQuery = this.$items.eq(index);
        const left: number = $target.position().left;
        const right: number = this.getContentWidth() - left;
        const border = this.parentWidth;

        // 右端の座標がウインドウを
        if (directionRight) {
            if (right < border) {
                const nextIndex = index + 1;

                if (nextIndex < this.length - 1) {
                    return this.checkAt(nextIndex, directionRight);
                }
                else {
                    return this.length - 1;
                }
            }
            else {
                return index;
            }
        }
        else {
            if (jump) {
                return index;
            }
            else {
                if (right < border) {
                    const nextIndex = index - 1;

                    if (nextIndex > 0) {
                        return this.checkAt(nextIndex, directionRight);
                    }
                    else {
                        return 0;
                    }
                }
                else {
                    return index;
                }
            }
        }
    }

}