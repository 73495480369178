import { DeviceUtil } from "../../umadash.js/util/DeviceUtil";
import { EventDispatcher } from "../../umadash.js/event/EventDispatcher";
import Event from "../../umadash.js/event/Event";

export class NavigationItem extends EventDispatcher {

    public static Enter: string = 'Enter';

    private $elm: JQuery;
    private parentWidth: number;

    private $anchor: JQuery;
    private $container: JQuery;
    private isOpened: boolean;

    constructor($elm: JQuery) {
        super();

        this.$elm = $elm;

        this.$anchor = $elm.find('.navigationItem__anchor');
        this.$container = $elm.find('.containerBalloonItem');
    }

    private showContainer(): void {
        this.$container.removeClass('hidden');
    }

    public hideContainer(): void {
        this.$container.addClass('hidden');
    }

    public open(): void {
        if (this.isOpened) return;

        this.$container.css('height', 'auto');
        const height = this.$container.outerHeight();
        console.log('height', height);
        this.$container.css('height', 0).delay(.01).css('height', height);

        this.$elm.addClass('open');

        this.isOpened = true;
    }

    public close(): void {
        if (!this.isOpened) return;
        this.$container.css('height', 0);
        this.$elm.removeClass('open');
        this.isOpened = false;
    }

    public resize(parentWidth: number): void {
        this.$anchor.off("click");
        if (!DeviceUtil.isDesktop()) {
            this.$anchor.on('click', (e) => {
                e.preventDefault();

                if (this.isOpened) {
                    this.close();
                }
                else {
                    this.open();
                }
            });
        }

        this.parentWidth = parentWidth;
    }

}