import { DeviceUtil } from "../../umadash.js/util/DeviceUtil";

var $ = jQuery;

export class FlexibleBackground {

    private $elm: JQuery;

    constructor($elm: JQuery) {
        this.$elm = $elm;
    }

    resize(): void {
        let left, top, scale;

        if (DeviceUtil.isDesktop()) {
            left = parseFloat(this.$elm.attr('data-left'));
            top = parseFloat(this.$elm.attr('data-top'));
            scale = parseFloat(this.$elm.attr('data-scale'));
        }
        else {
            left = parseFloat(this.$elm.attr('data-left-sp'));
            top = parseFloat(this.$elm.attr('data-top-sp'));
            scale = parseFloat(this.$elm.attr('data-scale-sp'));
        }
        console.log(left, top, scale);

        this.$elm.css({
            'background-position': `${left}% ${top}%`,
            'background-size': `${scale}px`
        });
    }

}