import { GroupSlider } from "./views/GroupSlider";
import { HSlider } from "./views/HSlider";
import { WindowWatcher, ResizeEvent } from "../umadash.js/util/WindowWatcher";
import { EntryBase } from "./EntryBase";
import { StaffRoll } from "./views/StaffRoll";
import { GlobalHeader } from "./views/GlobalHeader";
import { DivisionCircle } from "./views/DivisionCircle";
import { SectionWatcher } from "../umadash.js/util/SectionWatcher";
import { KeyboardWatcher, KeyboardEvent } from "../umadash.js/util/KeyboardWatcher";
import { KeyCode } from "../umadash.js/browser/KeyCode";
import { SnsUtil } from "../umadash.js/util/SnsUtil";
import { FlexibleBackground } from "./views/FlexibleBackground";

var $ = jQuery;

export class EntryCommon extends EntryBase {

    private sliders: GroupSlider[];
    private circles: DivisionCircle[];
    private staffRolls: StaffRoll[];
    private pageHero: FlexibleBackground;

    private sectionWatcher: SectionWatcher;
    private currentSlider: GroupSlider;

    private resizeHandler: () => void;
    private scrollHandler: () => void;

    constructor() {
        super();

        this.resizeHandler = this.resize.bind(this);
        this.scrollHandler = this.scroll.bind(this);
    }

    public run(): void {
        super.run(() => {

            SnsUtil.enable($('.icon--facebook'), $('.icon--twitter'));

            this.setupCircles();
            this.setupStaffRoll();
            this.setupSliders();

            const sections = $('.sectionDivision');
            if (sections.length > 0) {
                this.sectionWatcher = new SectionWatcher(sections);
            }

            const $pageHero = $('#js-pageHero');
            if ($pageHero.length > 0) {
                this.pageHero = new FlexibleBackground($pageHero);
            }

            WindowWatcher.getInstance().addEventListener(WindowWatcher.Resize, this.resizeHandler);
            WindowWatcher.getInstance().addEventListener(WindowWatcher.Scroll, this.scrollHandler);

            if (this.sectionWatcher) {
                this.sectionWatcher.addEventListener(SectionWatcher.Change, () => {
                    const i: number = this.sectionWatcher.getCurrentIndex();
                    this.currentSlider = this.sliders[i];
                });
            }

            KeyboardWatcher.getInstance().addEventListener(KeyboardWatcher.KeyDown, (e: KeyboardEvent) => {
                const keyCode: number = e.getKeyCode();
                switch (keyCode) {
                    case KeyCode.RightArrow:

                        if (this.currentSlider) {
                            this.currentSlider.moveLeft();
                        }
                        break;


                    case KeyCode.LeftArrow:

                        if (this.currentSlider) {
                            this.currentSlider.moveRight();
                        }
                        break;


                    default: break;
                }

            })

            GlobalHeader.getInstance().setup();
            GlobalHeader.getInstance().start();

            this.resize();
            this.scroll();

            for (let i = 0; i < this.staffRolls.length; i += 1) {
                const s: StaffRoll = this.staffRolls[i];
                s.start();
            }

            WindowWatcher.getInstance().start();
            KeyboardWatcher.getInstance().start();
        });
    }

    private resize(e: ResizeEvent = null): void {
        for (let i = 0; i < this.staffRolls.length; i += 1) {
            const s: StaffRoll = this.staffRolls[i];
            s.resize();
        }

        for (let i = 0; i < this.sliders.length; i += 1) {
            const s: GroupSlider = this.sliders[i];
            s.resize();
        }

        for (let i = 0; i < this.circles.length; i += 1) {
            const c: DivisionCircle = this.circles[i];
            c.resize();
        }

        if (this.pageHero) {
            this.pageHero.resize();
        }

        GlobalHeader.getInstance().resize();
    }

    private scroll(): void {
        const scrollTop: number = window.pageYOffset;

        GlobalHeader.getInstance().update(scrollTop);
        if (this.sectionWatcher) this.sectionWatcher.updateByScroll(scrollTop);
    }

    private setupStaffRoll(): void {
        this.staffRolls = [];
        const $elements = $('.footerRoll');
        $elements.each((index: number, element: HTMLElement) => {
            const $element: JQuery = $(element);
            this.staffRolls.push(new StaffRoll($element));
        });
    }

    private setupSliders(): void {
        this.sliders = [];
        const $sliders: JQuery = $('.groupSlider');
        $sliders.each((index: number, element: HTMLElement) => {
            const $element: JQuery = $(element);
            const s: GroupSlider = new GroupSlider($element);
            this.sliders.push(s);
        });
    }

    private setupCircles(): void {
        this.circles = [];
        const $divisionCircles = $('.sectionTvfication').find('.divisionCircle');
        $divisionCircles.each((index: number, element: HTMLElement) => {
            const ds: DivisionCircle = new DivisionCircle($(element), true);
            this.circles.push(ds);
        });
    }

}