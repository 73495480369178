import { MainNavigation } from "./MainNavigation";
import { DateUtil } from "../../umadash.js/util/DateUtil";
import { NumberUtil } from "../../umadash.js/util/NumberUtil";

export class GlobalHeader {

    private static instance: GlobalHeader;

    private $elm: JQuery;
    private $logo: JQuery;
    private $time: JQuery;
    private $menu: JQuery;

    private fixed: number;
    private isBlack: boolean;
    private hasSetuped: boolean;

    private mainNavigation: MainNavigation;
    private timer: any;
    private logo: boolean;

    private constructor() {

        this.$elm = $('#js-globalHeader');
        this.$logo = this.$elm.find('.globalHeader__logo');
        this.$time = this.$elm.find('.globalHeader__time');
        this.$menu = this.$elm.find('.globalHeader__menu');

        this.mainNavigation = new MainNavigation($('#js-mainNavigation'));

        this.hasSetuped = false;
        this.isBlack = this.$elm.hasClass('black');
    }

    public static getInstance(): GlobalHeader {
        if (!this.instance) {
            this.instance = new GlobalHeader();
        }
        return this.instance;
    }

    public setup() {
        if (this.hasSetuped) return;
        this.hasSetuped = true;

        this.$menu.on('click', () => {
            this.mainNavigation.open();
        });
    }

    private getIsOpened(): boolean {
        return this.$menu.hasClass('menu--close');
    }

    public update(scrollTop: number): void {

        if (scrollTop > 0) {
            this.$elm.addClass('fixed');
            this.$elm.removeClass('black');
        }
        else {
            this.$elm.removeClass('fixed');
            if (this.isBlack) {
                this.$elm.addClass('black');
            }
        }
    }

    public resize(): void {
        this.mainNavigation.resize();
    }

    public updateTime(): void {
        const now: Date = new Date();
        const hour: number = now.getHours();
        const minutes: number = now.getMinutes();
        this.$time.text(`${hour}：${NumberUtil.addZeroIfNeed(minutes)}`)
    }

    public start(): void {
        this.logo = true;
        this.updateTime();
        this.timer = setInterval(() => {
            this.change();
            this.updateTime();
        }, 5000);
    }

    public stop(): void {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    private change(): void {
        this.logo = !this.logo;
        if (this.logo) {
            this.$logo.removeClass('hidden');
            this.$time.addClass('hidden');
        }
        else {
            this.$logo.addClass('hidden');
            this.$time.removeClass('hidden');
        }

    }

}