import { View } from "../../umadash.js/view/View";
import { CommandUtil } from "../../umadash.js/util/CommandUtil";
import { Command } from "../../umadash.js/command/Command";

export class StaffRoll extends View<JQuery> {

    private $elm: JQuery;
    private $inner: JQuery;

    private vx: number;
    private x: number;
    private width: number;
    private updateHandler: () => void;
    private request: any;


    constructor($elm: JQuery) {
        super();

        this.$elm = $elm;
        this.$inner = $elm.find('.footerRoll__inner');

        this.x = 0;
        this.vx = -2;

        this.updateHandler = this.update.bind(this);
    }

    public start(): void {
        this.stop();



        this.update();
    }

    public stop(): void {
        if (this.request) {
            cancelAnimationFrame(this.request);
            this.request = null;
        }
    }

    private update() {

        let nextX: number = this.x + this.vx;
        if (nextX < -(this.width + window.innerWidth)) {
            nextX = window.innerWidth;
        }

        this.$inner.css({
            transform: `translate3d(${nextX}px, 0, 0)`
        });
        this.x = nextX;

        this.request = requestAnimationFrame(this.updateHandler);
    }

    public resize(): void {
        this.stop();
        this.$inner.css({
            transform: 'none',
            width: 9999
        });

        const $children: JQuery = this.$inner.children()
        const $lastItem: JQuery = $children.last();

        const left: number = $lastItem.offset().left;
        const width: number = $lastItem.outerWidth();

        const innerWidth: number = left + width + 100;

        this.$inner.css({
            width: innerWidth
        });
        this.width = innerWidth;
        this.start();
    }

    protected getShowCommand(execute: boolean): Command {
        return CommandUtil.serial([

        ], execute);
    }

    protected getHideCommand(execute: boolean): Command {
        return CommandUtil.serial([

        ], execute);
    }


}