import { SmoothScroll } from "../umadash.js/util/SmoothScroll";

export abstract class EntryBase {
  constructor() {}

  public run(onFontLoaded: () => void): void {
    onFontLoaded();
    // (<any>window).Ts.onFontLoaded(onFontLoaded);
    // (<any>window).Ts.loadFont();
  }
}
