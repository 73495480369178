import { View } from "../../umadash.js/view/View";
import { Command } from "../../umadash.js/command/Command";
import { CommandUtil } from "../../umadash.js/util/CommandUtil";
import { HSlider } from "./HSlider";
import { JqueryUtil } from "../../umadash.js/util/JqueryUtil";
import { Easing } from "../../umadash.js/tween/Easing";

export class GroupSlider extends View<JQuery> {

    private $elm: JQuery;

    private hSlider: HSlider;
    private $ui: JQuery;
    private $btnLeft: JQuery;
    private $btnRight: JQuery;

    constructor($elm: JQuery) {
        super();

        this.$elm = $elm;

        this.hSlider = new HSlider($elm.find('.hSlider'));
        this.$ui = $elm.find('.groupSlider__ui');
        this.$btnLeft = $elm.find('.groupSlider__btnLeft');
        this.$btnRight = $elm.find('.groupSlider__btnRight');

        this.$btnLeft.on('click', () => {
            this.hSlider.moveRight();

            // this.disableBtn(this.hSlider.getCurrentIndex());
        });

        this.$btnRight.on('click', () => {
            this.hSlider.moveLeft();
        });
    }

    private getOverflow(): boolean {
        return this.hSlider.getWidth() > this.$elm.outerWidth();
    }


    private showUICommand: Command;
    private hideUICommand: Command;

    private cancelUICommands(): void {
        if (this.showUICommand) {
            this.showUICommand.interrupt();
            this.showUICommand = null;
        }

        if (this.hideUICommand) {
            this.hideUICommand.interrupt();
            this.hideUICommand = null;
        }
    }

    private showUI(): void {
        this.hSlider.enable();
        if (this.getOverflow()) {
            this.hSlider.enable();
            this.$ui.addClass('js-show');
        }
        else {
            this.hSlider.disable();
        }
    }

    private hideUI(): void {
        this.$ui.removeClass('js-show');
    }

    public movable(): boolean {
        return this.getOverflow();
    }

    public moveRight(): void {
        if (this.getOverflow()) this.hSlider.moveRight();
    }

    public moveLeft(): void {
        if (this.getOverflow()) this.hSlider.moveLeft();
    }

    private getShowUICommand(execute: boolean): Command {
        return JqueryUtil.fadeIn(this.$ui, 300, Easing.linear, 'flex', true, execute);
    }

    private getHideUICommand(execute: boolean): Command {
        return JqueryUtil.fadeOut(this.$ui, 300, Easing.linear, 'none', true, execute);
    }

    private timer: any;
    public resize(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.hSlider.enable();

        this.timer = setTimeout(() => {
            this.hSlider.resize(this.$elm.outerWidth());
            this.showUI();
        }, 100);
    }

    protected getShowCommand(execute: boolean): Command {
        return CommandUtil.serial([

        ], execute);
    }

    protected getHideCommand(execute: boolean): Command {
        return CommandUtil.serial([

        ], execute);
    }

}