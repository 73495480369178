import { NavigationItem } from "./NavigationItem";
import Event from "../../umadash.js/event/Event";

export class MainNavigation {


    private $elm: JQuery;
    private $inner: JQuery;
    private $close: JQuery;
    private $bg: JQuery;
    private scrollTop: number;

    private navigationItems: NavigationItem[];
    private currentNavigationItem: NavigationItem;

    constructor($elm: JQuery) {

        this.$elm = $elm;


        this.$close = $elm.find('.mainNavigation__close');
        this.$bg = $elm.find('.mainNavigation__bg');
        this.$inner = $elm.find('.mainNavigation__inner');

        this.navigationItems = [];
        const $navigationItems: JQuery = $elm.find('.navigationItem--hasDisclosure');
        $navigationItems.each((index: number, element: HTMLElement) => {
            const item: NavigationItem = new NavigationItem($(element));
            item.addEventListener(NavigationItem.Enter, (e: Event) => {
                const width: number = e.getData().width;
                const outerWidth: number = this.$elm.outerWidth();
                const height: number = 110;

                if (width > outerWidth) {
                    this.$inner.css({
                        width,
                    });
                }

                this.$inner.css({
                    height,
                });

                if (this.currentNavigationItem && this.currentNavigationItem !== e.getTarget()) {
                    this.currentNavigationItem.hideContainer();
                }
                this.currentNavigationItem = <NavigationItem>(e.getTarget());
            });
            this.navigationItems.push(item);
        });

        this.ready();
    }

    private ready(): void {
        this.$close.on('click', () => {
            this.close();
        });

        this.$bg.on('click', () => {
            this.close();
        });

        this.$inner.on('mouseleave', () => {
            for (let i = 0; i < this.navigationItems.length; i++) {
                const item: NavigationItem = this.navigationItems[i];
                item.hideContainer();
            }

            if (this.currentNavigationItem) {
                this.currentNavigationItem.hideContainer();
                this.currentNavigationItem = null;
            }
        });
    }

    private getOpened(): boolean {
        return !this.$elm.hasClass('js-hidden');
    }

    public open(): void {
        this.$elm.removeClass('js-hidden');

        this.scrollTop = window.pageYOffset;
        $('#js-wrapper').css({
            position: 'fixed',
            width: '100%',
            top: -(this.scrollTop),
            left: 0
        });
    }

    public close(): void {
        this.$elm.addClass('js-hidden');
        $('#js-wrapper').css({
            position: 'relative',
            top: 'unset',
            left: 'unset'
        });
        window.scrollTo(0, this.scrollTop);
    }

    public resize(): void {
        for (let i = 0; i < this.navigationItems.length; i += 1) {
            const item = this.navigationItems[i];
            const width: number = this.$inner.outerWidth();
            item.resize(width);
        }
    }

}